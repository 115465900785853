.passwordBlock {
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
  position: relative;
}

.passwordHelper {
  color: #999999;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  padding-bottom: 12px;
}

.loginPolicy {
  display: flex;
  padding-bottom: 12px;
}

.loginCheckbox {
  padding: 0 6px 0 0 !important;
  display: block !important;
}

.signInButton {
  text-align: center;
  font-size: 16px;
  color: #333333;
  line-height: 22px;

  & > a {
    padding-left: 16px;
  }
}
