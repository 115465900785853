.dialog {
  div:nth-child(3) {
    justify-content: unset;
    align-items: flex-end;

    & > div {
      background: #333333;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2), 0px 0px 12px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
    }
  }

  .dialogContent {
    padding: 16px 16px 8px !important;
    
    & > p {
      margin: 0;
      color: #FFFFFF;
      opacity: 0.8;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .dialogButton {
    display: block;
    padding: 0 16px 16px;

    & > button {
      padding: 0;
      margin: 0;
      min-width: unset;
      color: #FFFFFF;
      font-weight: 700;
      font-size: 14px;
    }
  }
}