@font-face {
    font-family: 'ecom-icon';
    src:  url('fonts/ecom-icon.eot?c2jjkg');
    src:  url('fonts/ecom-icon.eot?c2jjkg#iefix') format('embedded-opentype'),
      url('fonts/ecom-icon.ttf?c2jjkg') format('truetype'),
      url('fonts/ecom-icon.woff?c2jjkg') format('woff'),
      url('fonts/ecom-icon.svg?c2jjkg#ecom-icon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="ecom-icon-"], [class*=" ecom-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'ecom-icon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .ecom-icon-arrow-back:before {
    content: "\e900";
    color: #666;
  }
  .ecom-icon-more-horiz:before {
    content: "\e901";
    color: #999;
  }
  .ecom-icon-delete:before {
    content: "\e902";
    color: #e24f4f;
  }
  .ecom-icon-Union:before {
    content: "\e903";
    color: #666;
  }
  .ecom-icon-cancel:before {
    content: "\e904";
    color: #666;
  }
  .ecom-icon-search:before {
    content: "\e905";
    color: #999;
  }
  .ecom-icon-label:before {
    content: "\e906";
    color: #666;
  }