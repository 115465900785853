//
// Page - User Login 1
// Pages SASS files are compiled into separate css files
//

// Global config
@import "../../config";

// Layout config
@import "../../global/layout/config.scss";

// Login Base
.kt-login.kt-login--v1 {
  // Aside
  .kt-login__aside {
    width: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Manrope";

    .kt-login__logo-bottom {
      & img {
        width: 100%;
      }
    }

    .kt-login__logo {
      display: flex;
      justify-content: center;
      position: absolute;
      right: 20px;
      top: 10px;

      & > img {
        height: 55px;
      }
    }

    .kt-login__title {
      color: #ffffff;
      font-size: 2rem;
      font-weight: 500;
    }

    .kt-login__subtitle {
      font-size: 1.2rem;
      font-weight: 200;
      margin: 2.5rem 0 3.5rem 0;
      color: rgba(#fff, 0.7);
    }

    .kt-login__sub {
      margin: 2rem 0 0 0;
      padding: 0 10rem;

      .kt-login__header {
        font-size: 34px;
        font-weight: 800;
        color: #ffffff;
        line-height: 74px;
      }

      .kt-login__sub-header {
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        line-height: 32px;
      }
    }

    .kt-login__info {
      display: flex;
      justify-content: space-between;

      .kt-login__menu {
        > a {
          text-decoration: none;
          color: #fff;
          margin-right: 2rem;
          display: inline-block;

          @include kt-link-color(rgba(#fff, 0.7), #fff);

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .kt-login__copyright {
        color: rgba(#fff, 0.4);
      }
    }
  }

  // Wrapper
  .kt-login__wrapper {
    padding: 3rem 3rem;
    background: #f7f7fe;

    // Sign out in subscription
    .kt-logout__custom {
      text-align: right;
      > a > button {
        background: #f05d7a;
        box-shadow: 0px 0px 10px #f05d7a;
        color: #ffffff;
        border-radius: 30px;
        font-weight: 600;
        font-size: 1rem;
        padding-left: 2.75rem;
        padding-right: 2.75rem;
      }
    }

    // Head
    .kt-login__head {
      font-size: 1rem;
      font-weight: 500;
      text-align: right;

      .kt-login__signup-label {
        color: kt-base-color(label, 2);
      }

      .kt-login__signup-link {
        color: kt-brand-color();
      }
    }

    // Body
    .kt-login__body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      // Sign up

      .kt-sign-up {
        position: absolute;
        top: 0;
        right: 0;
        > span {
          margin-right: 1.5rem;
          font-weight: 600;
          color: #17174b;
        }
        > a > button {
          background: #34d67a;
          box-shadow: 0px 0px 10px rgba(52, 214, 122, 0.5);
          color: #ffffff;
          border-radius: 30px;
          font-weight: 600;
          font-size: 1rem;
          padding-left: 2.75rem;
          padding-right: 2.75rem;
        }
      }
      // Form Wrapper
      .kt-login__form {
        width: 100%;
        max-width: 520px;
        font-family: "Manrope";

        .kt-login__back {
          font-size: 14px;
          font-weight: 400;
          color: #3a3a3a;
          line-height: 19px;
          margin-bottom: 23px;

          a {
            margin-right: 15px;
            color: #1a1a1a;
            > svg {
              width: 22px;
              height: 22px;
            }
          }
        }

        .kt-login__title {
          display: block;
          text-decoration: none;

          > h3 {
            color: #1a1a1a;
            font-style: normal;
            font-weight: 600;
            font-size: 50px;
            line-height: 68px;
            margin-bottom: 17px;
          }

          .login-subtitle {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            color: #3c3c3c;
            line-height: 32px;
          }
        }

        // Form
        .kt-form {
          margin: 4rem auto 0;

          .form-group {
            padding: 0;
            margin: 30px auto 0 !important;

            .MuiFormControl-marginNormal {
              margin: 0;
            }

            .label-custom {
              color: #7478ed;
              font-weight: 400;
              font-size: 18px;
              line-height: 16px;
              margin-left: 0.5rem;
            }

            .custom-form-label {
              .MuiInputBase-root {
                > input {
                  border: 1px solid #dddee3;
                  background-color: #ffffff !important;
                  border-radius: 28px;
                  padding: 19px 23px;
                  font-size: 16px;
                  color: #3c3c3c;
                  &::placeholder {
                    color: #babbcc;
                  }
                }
              }
              .MuiInput-underline:before {
                content: "";
                border-bottom: none !important;
              }
              .MuiInput-underline:after {
                content: "";
                border-bottom: none;
              }
              .MuiFormHelperText-root {
                font-size: 13px;
                margin-top: 6px;
              }
            }

            .form-control {
              border: none;
              height: 50px;
              margin-top: 1.25rem;
              background-color: rgba(#f7f7f9, 0.7);
              padding-left: 1.25rem;
              padding-right: 1.25rem;
              transition: background-color 0.3s ease;

              &:focus {
                transition: background-color 0.3s ease;
                background-color: #f7f7f9;
              }
            }
          }

          .form-group:first-child {
            margin-top: 0 !important;
          }
        }

        // Action
        .kt-login__actions {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin: 6px 0 0;

          .kt-login__link-forgot {
            font-weight: 400;
            @include kt-link-color(kt-base-color(label, 2), kt-brand-color());
            color: #3c3c3c !important;
            font-size: 16px;
            line-height: 32px;
            margin-bottom: 20px;
            text-align: right;
          }

          //   .kt-login__btn-secondary,
          .kt-login__btn-primary {
            background: #787cf0;
            color: #ffffff;
            border-radius: 28px;
            font-weight: 600;
            font-size: 16px;
            height: 56px;
            padding-left: 2.75rem;
            padding-right: 2.75rem;
            width: 100%
          }

          .kt-login__btn-secondary {
            background: #5d78ff;
            box-shadow: 0px 0px 10px rgba(240, 93, 122, 0.1);
            color: #ffffff;
            border-radius: 30px;
            font-weight: 600;
            font-size: 1rem;
            height: 50px;
            padding-left: 2.75rem;
            padding-right: 2.75rem;
            margin-bottom: 1.5rem;
          }

          .kt-login__forgot-button {
            text-align: right;
          }

          .kt-login__signup-wrapper {
            margin-top: 145px;
            display: flex;
            justify-content: space-between;
          }

          .kt-login__signup {
            font-size: 14px;
            color: #3a3a3a;
            font-weight: 400;

            .kt-login__link-register {
              margin-left: 10px;
              color: #787cf0;
            }
          }
        }

        .kt-login__policy {
          font-size: 14px;
          color: #3a3a3a;
          font-weight: 400;
          line-height: 19px;
          margin: 22px 0 32px;
          display: flex;

          a {
            color: #787cf0;
          }

          .MuiCheckbox-root {
            padding: 0;
            margin-right: 18px;
            align-items: flex-start;
            &:hover {
              background-color: transparent;
            }
          }

          .MuiCheckbox-colorPrimary.Mui-checked {
            color: #787cf0;
          }
        }

        // Divider
        .kt-login__divider {
          margin: 1rem 0 2rem 0;

          &:not(:first-child):not(:last-child) {
            font-weight: 400;
            color: #b5b2c3;
            font-size: 1rem;
          }
        }

        // Options
        .kt-login__options {
          display: flex;
          justify-content: center;
          justify-content: space-between;
          max-width: 100%;

          > a {
            text-decoration: none;
            flex: 1;
            justify-content: center;
            align-items: center;
            display: flex;

            &:not(:last-child) {
              margin: 0 1.5rem 0 0;
            }
          }
        }
      }
    }
  }

  @include kt-desktop {
    // Aside
    .kt-login__aside {
      flex: 1;
    }
  }

  @include kt-tablet-and-mobile() {
    .kt-grid--hor-tablet-and-mobile {
      display: flex;
      flex-direction: column-reverse;
    }

    // Aside
    .kt-login__aside {
      width: 100%;
      height: auto;

      .kt-login__logo-bottom {
        & img {
          width: 100%;
        }
      }

      .kt-login__logo {
        margin-bottom: 2.5rem;
        position: absolute;
        right: 20px;
        top: 10px;
      }

      .kt-login__info {
        margin-top: 2rem;
      }

      .kt-login__subtitle {
        margin: 2rem 0;
      }
    }

    // Wrapper
    .kt-login__wrapper {
      padding: 3rem 1.5rem;

      .kt-login__head {
        padding-left: 2rem;
        right: 2rem;
      }

      .kt-login__body {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 5rem;

        .kt-login__form {
          .kt-login__options {
            > a {
              &:not(:first-child):not(:last-child) {
                margin: 0 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}
