.verificationContent {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: #666666;
}

.verifyEmail {
  padding: 4px 0;
  color: #333333;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
}

.resendBtn {
  margin: 16px 0 40px;
  border: 1px solid rgba(46, 49, 94, 0.16) !important;
  border-radius: 6px !important;
  padding: 8px !important;
}

.contactSupport {
  padding-top: 40px;

  & > span {
    color: #555eed;
  }
}