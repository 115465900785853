// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}
.kt-header-menu-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

body {
  font-family: "Manrope", "Avenir", "Times New Roman", Times, serif;
  font-size: 14px;
}

.bgcolor-white {
    background-color: white;
}
.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

//custom
.kt-container {
  width: 90%;
  margin: 0 auto;
}

.highcharts-container {
  width: 100% !important;
}

.stockcharts-wrapper {
  width: 100%;
  vertical-align: top !important;
  .highcharts-container {
    min-height: 600px;
  }
}

.stripe-inputs {
  box-shadow: none;
  border: 1px solid #c0c1da;
  font-family: "Manrope";
  font-size: 18px;
  display: "flex";
  align-items: center;
  border-radius: 4px;
}

.card-logo {
  image-rendering: optimizeQuality;
  // mix-blend-mode: multiply;
}

.overflow-hidden {
  overflow: hidden;
}

// Tag border classes
$border-colors: (
  "default": #8C8C8C,
  "green": #0CB092,
  "yellow": #F4E76E,
  "orange": #F27D00,
  "red": #E24F4F,
  "purple": #B726FF,
  "lilac": #086CE2
);

@each $name, $color in $border-colors {
  .color-#{$name} {
    color: $color !important;
    &::before {
      color: $color !important;
    }
  }
  .border-#{$name} {
    border: 2px solid $color !important;
  }
  .border-tb-#{$name} {
    border-top: 2px solid $color !important;
    border-bottom: 2px solid $color !important;
  }
  .border-ltb-#{$name} {
    border-left: 2px solid $color !important;
    border-top: 2px solid $color !important;
    border-bottom: 2px solid $color !important;
  }
  .border-rtb-#{$name} {
    border-right: 2px solid $color !important;
    border-top: 2px solid $color !important;
    border-bottom: 2px solid $color !important;
  }
}

// Fixed header border classes for notes
.fixedTableWrapper {
  th {
    @each $name, $color in $border-colors {
      &.fixed-border-#{$name} {
        border: 2px solid $color !important;
      }
      &.fixed-border-tb-#{$name} {
        border-top: 2px solid $color !important;
        border-bottom: 2px solid $color !important;
      }
      &.fixed-border-ltb-#{$name} {
        border-left: 2px solid $color !important;
        border-top: 2px solid $color !important;
        border-bottom: 2px solid $color !important;
      }
      &.fixed-border-rtb-#{$name} {
        border-right: 2px solid $color !important;
        border-top: 2px solid $color !important;
        border-bottom: 2px solid $color !important;
      }
    }
  }
}
