@font-face {
  font-family: 'ecom-icon';
  src: url('fonts/ecom-icon.eot?wpxbku');
  src: url('fonts/ecom-icon.eot?wpxbku#iefix') format('embedded-opentype'),
  url('fonts/ecom-icon.ttf?wpxbku') format('truetype'),
  url('fonts/ecom-icon.woff?wpxbku') format('woff'),
  url('fonts/ecom-icon.svg?wpxbku#ecom-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ecom-icon-"], [class*=" ecom-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ecom-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ecom-icon-chevron-right:before {
  content: "\e900";
  color: #666;
}

.ecom-icon-chevron-left:before {
  content: "\e901";
  color: #666;
}

.ecom-icon-expand-less:before {
  content: "\e902";
  color: #666;
}

.ecom-icon-vpn-key:before {
  content: "\e903";
  color: #666;
}

.ecom-icon-update:before {
  content: "\e904";
}

.ecom-icon-save-alt:before {
  content: "\e905";
  color: #666;
}

.ecom-icon-more-horiz:before {
  content: "\e906";
  color: #666;
}

.ecom-icon-minimize:before {
  content: "\e907";
  color: #666;
}

.ecom-icon-lock:before {
  content: "\e908";
  color: #999;
}

.ecom-icon-launch:before {
  content: "\e909";
  color: #666;
}

.ecom-icon-info:before {
  content: "\e90a";
  color: #999;
}

.ecom-icon-fullscreen:before {
  content: "\e90b";
  color: #666;
}

.ecom-icon-fullscreen-exit:before {
  content: "\e90c";
  color: #666;
}

.ecom-icon-filter-list:before {
  content: "\e90d";
  color: #666;
}

.ecom-icon-file-copy:before {
  content: "\e90e";
  color: #666;
}

.ecom-icon-expand:before {
  content: "\e90f";
  color: #666;
}

.ecom-icon-expand-more:before {
  content: "\e910";
  color: #666;
}

.ecom-icon-edit:before {
  content: "\e911";
  color: #666;
}

.ecom-icon-drag-indicator:before {
  content: "\e912";
  color: #666;
}

.ecom-icon-done:before {
  content: "\e913";
}

.ecom-icon-description:before {
  content: "\e914";
  color: #666;
}

.ecom-icon-delete:before {
  content: "\e915";
  color: #666;
}

.ecom-icon-close:before {
  content: "\e916";
  color: #666;
}

.ecom-icon-cancel:before {
  content: "\e917";
  color: #666;
}

.ecom-icon-arrow-forward-ios:before {
  content: "\e918";
  color: #2e315e;
}

.ecom-icon-arrow-downward:before {
  content: "\e919";
}

.ecom-icon-arrow-back-ios:before {
  content: "\e91a";
  color: #666;
}

.ecom-icon-add-circle-outline:before {
  content: "\e91b";
  color: #fff;
}

.ecom-icon-filter-remove:before {
  content: "\e91c";
  color: #666;
}

.ecom-icon-close-modal:before {
  content: "\e91d";
  color: #666;
}

.ecom-icon-history-icon:before {
  content: "\e91e";
  color: #666;
}

.ecom-icon-create-project:before {
  content: "\e91f";
  color: #666;
}

.ecom-icon-pause:before {
  content: "\e920";
  color: #666;
}

.ecom-icon-tag:before {
  content: "\e921";
  color: #666;
}

.ecom-icon-search:before {
  content: "\e922";
  color: #666;
}

.ecom-icon-track-changes:before {
  content: "\e923";
  color: #666;
}

.ecom-icon-focus:before {
  content: "\e924";
  color: #666;
}

.ecom-icon-time-line:before {
  content: "\e925";
  color: #666;
}

.ecom-icon-menu:before {
  content: "\e926";
  color: #666;
}

.ecom-icon-arrow-dropdown:before {
  content: "\e927";
  color: #666;
}

.ecom-icon-hide-box:before {
  content: "\e928";
  color: #666;
}

.ecom-icon-settings:before {
  content: "\e929";
  color: #666;
}

.ecom-icon-organic-rank:before {
  content: "\e930";
  color: #666;
}

.ecom-icon-sponsored-rank:before {
  content: "\e931";
  color: #666;
}

.ecom-icon-switch-to-left:before {
  content: "\e932";
  color: #666;
}

.ecom-icon-switch-to-right:before {
  content: "\e933";
  color: #666;
}

.ecom-icon-note-add:before {
  content: "\e92a";
  color: #666;
}

.ecom-icon-calendar:before {
  content: "\e92b";
  color: #999;
}