.purchase {
  .purchaseSecurity {
    display: flex;
    align-items: center;

    & > img {
      width: 20px;
    }

    .purchaseSecurityText {
      font-size: 14px;
      color: #999999;
      line-height: 20px;
      padding-left: 10px;
    }
  }

  .purchasePlanName {
    padding-top: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
  }

  .purchasePlanSummary {
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    padding: 4px 0;
  }

  .purchaseBilled {
    border-top: 1px solid rgba(46, 49, 94, 0.16);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    padding-top: 4px;
  }

  .purchaseCard {
    padding-top: 36px;

    .purchaseCardInput {
      .purchaseCardLabel {
        color: #999999;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
      }

      & > div:nth-child(2) {
        margin: 4px 0 12px;
        border: 1px solid rgba(46, 49, 94, 0.16);
        border-radius: 8px;
        box-shadow: none;
      }
    }

    .purchaseCardRow {
      display: flex;

      .purchaseCardInput {
        width: 50%;
      }

      & > div:nth-child(1) {
        padding-right: 8px;
      }

      & > div:nth-child(2) {
        padding-left: 8px;
      }
    }

    .purchaseCardButton {
      margin-top: 32px;
      padding: 14px;
      background: linear-gradient(91.67deg, #565EED 0%, #2E37D1 99.88%);
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      width: 100%;
    }
  }

  .purchaseSummary {
    padding-top: 32px;
    text-align: center;
    color: #999999;
    font-size: 12px;
    line-height: 18px;
  }

  .countryLabel {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    padding-bottom: 4px;
    color: #999999;
  }

  .countryDropdown {
    & > div > div {
      & > div:nth-child(1) {
        padding: 10px 36px 10px 12px,
      }
    }
  }
}