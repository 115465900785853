.kt-menu-account__item {
    padding: 24px 7px 24px 15px !important;
    border-top: 1px solid var(--lines-2-st-pr, rgba(46, 49, 94, 0.08));
    &:hover {
        background-color: #f6f7fd;
    }
}

.kt-menu-account__item-active {
    background-color: rgba(46, 49, 94, 0.08);
}

.kt-menu-account__modal {
    z-index: 10001 !important;
    .MuiMenu-paper {
        min-width: 320px;
    }
}