.input-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 750px) {
  .input-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.kt-content {
  padding-bottom: 32px !important;
}

.main-table-head {
  display: none;
  position: relative;
  width: 100%;
  /* background-color: rgb(200, 202, 209); */
  height: 50px;
}

.main-table-head .material-icons {
  margin-bottom: 0;
  margin-left: 19px;
}

.main-table-head p {
  font-size: 0.75rem;
}

.spaned {
  display: none;
  position: relative;
  width: 100%;
  background-color: rgb(202, 210, 226);
  height: 50px;
}

/* .MuiGrid-container {
  width: 200px !important;
} */

.makeStyles-textField-5 {
  margin-left: 0 !important;
}

.how-to-container {
  margin-top: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.how-to-header-container {
  margin-bottom: 3rem;
}

.MuiIcon-colorDisabled {
  cursor: not-allowed;
}

table .MuiCircularProgress-root {
  margin-bottom: 12px;
}

.kt-header__bottom,
.kt-sticky-toolbar,
.kt-footer__top,
.kt-subheader {
  display: none !important;
}

.kt-header__brand {
  visibility: hidden;
}

.kt-header__topbar {
  visibility: hidden;
}

.kt-header__topbar-item--user {
  visibility: visible;
}

.checkbox-container {
  display: flex;
}

.none-background {
  background: none;
}

.form-group {
  margin-bottom: 1rem !important;
}

.StripeElement {
  padding: 0 16px 24px;
  margin: 0;
  border-bottom: 1px solid rgba(46, 49, 94, 0.16);
}
