.passwordBlock {
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
  position: relative;
}

.passwordIcon {
  right: 31px;
  top: 12px;
  width: 20px;
  position: absolute;
}

.confirmIcon {
  right: 8px;
  top: 12px;
  width: 20px;
  position: absolute;
}

.resendBtn {
  margin: 16px 0 40px;
  border: 1px solid rgba(46, 49, 94, 0.16) !important;
  border-radius: 6px !important;
  padding: 8px !important;
}

.tooltipInfo {
  position: absolute;
  top: 5px;
  right: 0;
}