.form-group {
    margin-bottom: 12px;
    position: relative;
  
    .label {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #999999;
      padding-bottom: 4px;
    }
  
    .custom-form-label {
      margin: 0;
  
      label {
        color: #999999;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
      }
  
      & > div {
        input {
          padding: 14px 12px;
          border: 1px solid rgba(46, 49, 94, 0.16);
          border-radius: 8px;
        }
  
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
  
        &::after {
          content: unset;
        }
  
        &::before {
          content: unset;
        }
      }
    }
  
    .hide-password-icon {
      width: 20px;
      position: absolute;
      right: 12px;
      top: 40px;
      cursor: pointer;
    }
  }