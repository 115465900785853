.toast-container {
  display: flex;

  .image {
    padding: 0 12px;
    display: flex;
    align-items: center;
  }

  .success {
    background: #C5F4E0;
  }

  .error {
    background: #FFD9D8;
  }

  .warning {
    background: #FBEDBB;
  }

  .info {
    background: #C8E1FF;
  }

  .toast-content {
    padding: 20px;

    .toast-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 4px;
      color: #333333;
      text-transform: capitalize;
    }

    .toast-summary {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
    }
  }
}

.Toastify__toast-body {
  padding: 0 !important;
}

.Toastify__close-button {
  padding: 20px !important;
}
.Toastify__toast-container {
  width: unset !important;
}
@media only screen and (max-width: 480px) {
  .Toastify__toast {
    min-width: unset !important;
    width: calc(100% + 13px);
  }
}
