.subscriptionLogo {
  padding-top: 31px;
  display: flex;
  justify-content: center;

  & > img {
    width: 140px;
  }
}

.subscriptionTitle {
  padding-top: 49px;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #1D2434;
  text-align: center;
}

.subscriptionSummary {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1D2434;
  padding-top: 8px;
  text-align: center;
}

.subscriptionOptions {
  padding-top: 20px;
}

.subscriptionButtonGroup {
  border: 1px solid #DDDDE4;
  border-radius: 8px;
  padding: 2px;
  display: flex;
  width: max-content;
  margin: 0 auto;
}

.subscriptionButton {
  padding: 6px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #333333;
  cursor: pointer;
}

.subscriptionButtonActive {
  background: #333333;
  border-radius: 6px;
  color: #FFFFFF;
}

.subscriptionSaveUp {
  padding-top: 19px;
  position: relative;
  width: max-content;
  margin: 0 auto;
  padding-right: 25px;

  & > img {
    width: 20px;
    position: absolute;
    right: 0;
    top: 12px;
  }
}

.planContainer {
  padding: 24px 48px 48px;
  display: flex;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0
  }

  .plans {
    width: calc(100% / 4);
    margin: 0 12px;
    box-shadow: 0px 2px 6px rgba(86, 94, 237, 0.2), 0px 10px 20px rgba(30, 38, 198, 0.12);
    border-radius: 18px;
    padding: 48px 32px 78px;
    min-width: 300px;

    .plansTitle {
      color: #000000;
      font-weight: 800;
      font-size: 24px;
      line-height: 20px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .plansMost {
        border: 1px solid rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        padding: 3px 6px;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        margin-left: 8px;
      }
    }

    .plansPrice {
      text-align: center;

      .plansOldPrice {
        color: #999999;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        text-decoration: line-through;
      }

      .plansNewPrice {
        color: #333333;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .plansText {
      color: #666666;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    .plansLetTask {
      padding-top: 32px;
      color: #333333;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      & > img {
        padding-left: 4px;
        width: 20px;
      }
    }

    .plansButton {
      width: 100%;
      padding: 14px;
      background: linear-gradient(91.67deg, #565EED 0%, #2E37D1 99.88%);
      border-radius: 8px;
      color: #FFFFFF;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin-top: 40px;
    }

    .buttonDisable {
      background: #DDDDE4;
      cursor: unset !important;
    }

    .plansSummary {
      padding-top: 24px;
      color: #1D2434;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
    }

    .plansTopBox {
      min-height: 207px;
      border-bottom: 1px solid rgba(46, 49, 94, 0.08);
      padding: 24px 0;
    }

    .unsetHeight {
      min-height: unset;
    }

    .plansInclude {
      padding: 24px 0;
      color: #999999;
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }

    .plansOptions {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
      padding-bottom: 20px;

      & > img {
        width: 20px;
        padding-right: 8px;
      }
    }
  }
}

@media (max-width: 768px) {
  .subscriptionContainer {
    padding: 0 16px;
  }

  .subscriptionLogo {
    padding-top: 24px;
  }

  .subscriptionTitle {
    padding-top: 32px;
    font-size: 20px;
  }

  .subscriptionSaveUp {
    display: none;
  }

  .planContainer {
    display: block;
    padding: 22px 0;

    .plans {
      width: 100%;
      margin: 0 0 12px;
      padding: 32px 16px 16px;

      .plansOptions {
        padding: 0 15px 20px;
      }
    }
  }
}
