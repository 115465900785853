//
// Page - User Login 1
// Pages SASS files are compiled into separate css files
//

// Global config
@import "../../config";

// Layout config
@import "../../global/layout/config.scss";

// Login Base
.login-form {
  max-width: 396px;
  margin: 0 auto;
  padding-top: 31px;

  .login-form-image {
    display: flex;
    justify-content: center;
    padding-bottom: 49px;

    img {
      width: 140px;
    }
  }

  .login-form-title {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #1D2434;
    text-align: center;
    padding-bottom: 48px;
  }

  .login-form-actions {
    .login-form-actions__forgot-button {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      padding-bottom: 20px;

      .forgot-button-link {
        color: #333333;
      }
    }

    .button-login {
      width: 100%;
      padding: 14px 0;
      color: #FFFFFF;
      background: linear-gradient(91.67deg, #565EED 0%, #2E37D1 99.88%);
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 20px;

      &:disabled {
        background: rgba(46, 49, 94, 0.16);
      }
    }

    .login-form-actions__signup-wrapper {
      display: flex;
      justify-content: center;

      .login-form-actions__signup {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #333333;

        .login-form-actions__link-register {
          padding-left: 16px;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #565EED;
        }
      }
    }
  }

  .login-form-summary {
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #333333;
    text-align: center;
    margin-top: -40px;
    margin-bottom: 40px;
  }

  .kt-login__back {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > a {
      color: #333333;
    }

    & > span {
      color: #666666;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      padding-left: 4px;
    }
  }

  .custom-form-label {
    & > p {
      margin-top: 4px;
      color: #E24F4F;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .success-icon {
    width: 20px;
    position: absolute;
    right: 10px;
    top: 36px;
  }

  .error-icon {
    width: 20px;
    position: absolute;
    right: 10px;
    top: 36px;
  }

  .success-icon-password {
    right: 38px;
    top: 37px;
  }

  .error-icon-password {
    right: 38px;
    top: 37px;
  }
}

@include kt-mobile{
  .login-form {
    padding: 16px;

    .login-form-image {
      padding-top: 11px;
      padding-bottom: 29px;
    }

    .login-form-title {
      font-size: 20px;
      padding-bottom: 16px;
    }

    .login-form-summary {
      max-width: 252px;
      margin: 0 auto 16px;
    }
  }
}
